import React from 'react'
import { DateInput, NullableBooleanInput, Filter, ReferenceInput, AutocompleteInput, SelectArrayInput, NumberInput, TextInput } from 'react-admin'

export const IntegrationFilters = ({ showUpdatedAtGte, showIncomplete, showEntityTypeId, showEndpoint, showIntegration, showDevelopment, showModuleConfiguration, showHasEntities, showStatusTypeId, showExternalCompanyNumber, showName, ...props }) => {
    const wildCardSearchName = (searchText) => ({name: {searchText: searchText, operation: "lk"}});
    return (
    <Filter {...props}>
            {showIntegration && <ReferenceInput filterToQuery={ wildCardSearchName } sort={{ field: 'name', order: 'ASC' }} perPage={100} label="Integration" source="integrationId" reference="integrations" alwaysOn allowEmpty>
            <AutocompleteInput allowEmpty={true} translateChoice={false} optionText="name" />
        </ReferenceInput>}

        <ReferenceInput sort={{ field: 'companyName', order: 'ASC' }} label="Company (Customer number)" source="companyId" reference="companies" alwaysOn allowEmpty className="width-220px">
            <AutocompleteInput allowEmpty={true} translateChoice={false} optionText={company => company.companyName === "" ? "" : company.companyName + " (" + company.customerNumber + ")"} />
        </ReferenceInput>

        {showExternalCompanyNumber && <NumberInput source="externalCompanyNumber" min={0} alwaysOn />}

        {showName && <TextInput source="name" alwaysOn allowEmpty />}

        {showEntityTypeId && <SelectArrayInput className="width-60px" label="Type" source="entityType" choices={[
            { id: '1', name: 'Invoice' },
            { id: '2', name: 'Invoice payment' },
            { id: '11', name: 'Supplier invoice' },
            { id: '12', name: 'Supplier invoice payment' },
            { id: '13', name: 'Supplier order response' },
            { id: '14', name: 'Supplier dispatch advice' },
            { id: '20', name: 'Time, grouped by date and code' },
            { id: '21', name: 'Time, continuous' },
            { id: '22', name: 'Time, grouped by date and code and project' },
            { id: '23', name: 'Time, continuous, with price' },
            { id: '29', name: 'Time, single' }
        ]} alwaysOn allowEmpty />}

        {showIncomplete && <NullableBooleanInput label="Failed" source="incomplete" alwaysOn />}

        {showStatusTypeId && <SelectArrayInput className="width-70px" label="Status" source="statusTypeId" choices={[
            { id: '1', name: 'In progress' },
            { id: '200', name: 'Succeeded' },
            { id: '201', name: 'Succeeded, but has errors' },
            { id: '202', name: 'Succeeded by user' }
        ]} alwaysOn allowEmpty />}

        {showUpdatedAtGte && <DateInput label="Updated after" source="updatedAt_gte" alwaysOn />}

        {showEndpoint && <ReferenceInput filter={!props.filterValues.companyId ? {} : { companyId: props.filterValues.companyId }} sort={{ field: 'endpointName', order: 'ASC' }} label="Endpoint" source="endpointId" reference="endpoints" alwaysOn allowEmpty>
            <AutocompleteInput allowEmpty={true} translateChoice={false} optionText="endpointName" />
        </ReferenceInput>}

        {showModuleConfiguration && <ReferenceInput filter={!props.filterValues.integrationId ? { parentId: null } : { parentId: null, integrationId: props.filterValues.integrationId }} sort={{ field: 'name', order: 'ASC' }} label="Entrypoint" source="entrypointModuleConfigurationId" reference="moduleconfigurations" alwaysOn allowEmpty>
            <AutocompleteInput allowEmpty={true} translateChoice={false} optionText="name" />
        </ReferenceInput>}

        {showDevelopment && <NullableBooleanInput source="development" alwaysOn />}
        {showHasEntities && <NullableBooleanInput source="hasEntities" alwaysOn />}
    </Filter>
    );
};