import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
} from 'ra-core';
import FakeRest from 'fakerest';

let portalAdminResources = ['companies', 'endpoints', 'companies/subdivisions'];
let fakerests = {};
let companyId;

const convertHTTPResponse = (response, type, resource) => {
    const { json } = response;
    switch (type) {
        case GET_LIST:
        case GET_MANY_REFERENCE:
        case GET_MANY:
            var raw = json.results == undefined ? json.data : json.results;

            return raw.map(res => {
                if (resource == 'companies/subdivisions') {
                    res.id = res.subdivisionId
                }

                if (resource == 'companies') {
                    res.id = res.companyId
                }

                if (resource == 'endpoints') {
                    res.id = res.endpointId
                }

                return res;
            });
    }
};

function getResponse(type, resource, params) {
    let restServer = fakerests[resource];
    switch (type) {
        case GET_LIST: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                sort: [field, order],
                range: [(page - 1) * perPage, page * perPage - 1],
                filter: resource != "companies" ? params.filter : (company) => {
                    if (!params.filter.q) { return true; }
                    if (company.companyName.toLowerCase().indexOf(params.filter.q.toLowerCase()) != -1) { return true; }
                    if (company.countryCode.toLowerCase().indexOf(params.filter.q.toLowerCase()) != -1) { return true; }
                    let customerNumberString = company.customerNumber.toString();
                    if (customerNumberString.toLowerCase().indexOf(params.filter.q.toLowerCase()) != -1) { return true; }
                    return false
                },
            };
            return {
                data: restServer.getAll(resource, query),
                total: restServer.getCount(resource, {
                    filter: params.filter,
                }),
            };
        }
        case GET_ONE:
            return {
                data: restServer.getOne(resource, params.id, { ...params }),
            };
        case GET_MANY:
            return {
                data: restServer.getAll(resource, {
                    filter: { id: params.ids },
                }),
            };
        case GET_MANY_REFERENCE: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                sort: [field, order],
                range: [(page - 1) * perPage, page * perPage - 1],
                filter: { ...params.filter, [params.target]: params.id },
            };
            return {
                data: restServer.getAll(resource, query),
                total: restServer.getCount(resource, {
                    filter: query.filter,
                }),
            };
        }
    }
};

export default (apiUrl, httpClient, requestHandler) => (type, resource, params) => {
    if (portalAdminResources.includes(resource)) {
        var queryResource = resource;

        if (params.filter && params.filter.companyId) {
            companyId = params.filter.companyId;
        }

        if (queryResource == 'companies/subdivisions' && companyId) {
            queryResource = `companies/${companyId}/querysubdivisions?pagesize=10000`;
            delete params.filter.companyId;
        }

        if (!fakerests[queryResource]) {
            var url = `${apiUrl}/${queryResource}`;
            var options = { method: 'GET' };

            return httpClient(url, options).then(response =>
                convertHTTPResponse(response, type, resource, params)
            )
            .then(result => {
                fakerests[queryResource] = new FakeRest.Server();
                fakerests[queryResource].init({ [queryResource]: result });
                return getResponse(type, queryResource, params);
            });
        } else {
            return new Promise(resolve => resolve(getResponse(type, queryResource, params)));
        }
    } else {
        return requestHandler(type, resource, params);
    }
}